@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 118%;
  }
  
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #about{
    width: 118%;
    padding: 0;
  }

  #portfolio {
    width: 118%;
  }
}
